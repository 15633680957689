import { createWebHistory, createRouter } from "vue-router";
const routes = [
  {
    path: "/landing",
    redirect: "/landing/visitors",
  },
  {
    path: "/landing/visitors",
    component: () => import("@/views/visitorsPage/VisitorsPage.vue"),
  },
  {
    path: "/landing/visualizer",
    component: () => import("@/views/visualizer/VisualizerApplication.vue"),
  },
  {
    path: "/landing/entrepreneurs",
    component: () => import("@/views/entrepreneursPage/EntrepreneursPage.vue"),
  },
  {
    path: "/landing/privacy-policy",
    component: () => import("@/views/PoliticasDePrivacidad.vue"),
  },
  {
    path: "/landing/profile/:id",
    component: () => import("@/views/profile/ProfileViewStartup.vue"),
  },
  {
    path: "/landing/profile-not-found",
    component: () => import("@/views/profile/ProfileNotFound.vue"),
  },

  // ,
  // {
  //   path: "/dashboard",
  //   redirect: "http://dashboard.araucaniainfinita.cl/",
  // }
];

// const catchAllRoute = { path: '/:pathMatch(.*)*', redirect: '/' };
// routes.push(catchAllRoute);

const router = createRouter({
  history: createWebHistory(),
  base: "/landing",
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
